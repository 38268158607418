import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getUsage } from "farmerjoe-common/lib/requests/billing";
import { isAdmin } from "farmerjoe-common/lib/utils/User";
import { UserRole, UserState } from "farmerjoe-common/lib/flow/types";
import { SUBSCRIPTION_PLAN_QUOTA_REACHED } from "farmerjoe-common/lib/constants/billing";

import "./style.css";
import Employee from "./Employee";
import EmployeesViewSwitch from "./EmployeesViewSwitch";
import { canAddNewEmployees, convertUserObjects } from "./utils";

import NoResults from "../Common/NoResults";
import FlatList from "../Common/FlatList";
import ScrollableTabView from "../Common/ScrollableTabView";
import TabBarSearchSort from "../Common/TabBarSearchSort";
import SearchInput from "../Common/SearchInput";
import Icon from "../Common/Icon";
import { CreateNewButton } from "../Common/NewButton";
import { Loading } from "../Loading/Loading";
import GlobalUserSearch from "../GlobalUserSearch/GlobalUserSearch";
import { NotActivatedWarning } from "../Activation/NotActivatedWarning";
import { AlertDialog } from "../Dialog/Dialog";
import I18n from "../../language/i18n";
import { getUidToken } from "../../utils/auth";

// TODO: improve typings
type Props = any;
type State = any;

export default class Employees extends Component<Props, State> {
  state: State = {
    showCreateEmployeeDialog: false,
    alertDialog: {
      isVisible: false,
    },
    loading: false,
  };

  async onClick() {
    const { company } = this.props;
    const uidToken = await getUidToken();

    this.setState({ loading: true });
    return getUsage(uidToken, company.key)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Unexpected error occured! Try again!");
        }
        const content = await response.json();
        if (content.totalUsers >= content.maxAllowedUsers) {
          throw new Error(SUBSCRIPTION_PLAN_QUOTA_REACHED);
        }
        this.setState({ showCreateEmployeeDialog: true });
      })
      .catch(error => {
        this.handleCheckUsageLimitsError(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  private handleCheckUsageLimitsError(error: Error) {
    if (error.message === SUBSCRIPTION_PLAN_QUOTA_REACHED) {
      const message = <div>
        <div> {I18n.t("billing.addMoreSeats")} </div>
        <Link
          key={"billing"}
          to={`/company/${this.props.company.key}/billing`}
          onClick={this.closeAlert.bind(this)}
        >
          {I18n.t("billing.editSeats")} &#8594;
        </Link>
      </div>;
      this.setState({ showCreateEmployeeDialog: false });
      this.showAlert(I18n.t("billing.addSeats"), message);
      return;
    }

    this.setState({ showCreateEmployeeDialog: false });
    this.showAlert(I18n.t("error"), error.message);
  }

  private showAlert(title: string, message: string | React.ReactNode) {
    this.setState({
      alertDialog: { isVisible: true, title, message },
    });
  }

  private closeAlert() {
    this.setState({
      alertDialog: { isVisible: false },
    });
  }

  render() {
    let {
      users,
      company,
      loading,
      myCompanyProfile,
      actions,
      search,
      filter,
      selectedEmployee,
      browsingGroup,
      auth,
    } = this.props;

    if (loading || this.state.loading) {
      return <Loading />;
    }

    users = convertUserObjects(users, auth.uid);
    const employees = users.filter(
      user => user.state !== UserState.Deactivated && user.role !== UserRole.Advisor,
    );
    const advisors = users.filter(
      user => user.state !== UserState.Deactivated && user.role === UserRole.Advisor,
    );
    const deactivated = users.filter(user => user.state && user.state === UserState.Deactivated);

    const showNew = canAddNewEmployees(myCompanyProfile, auth);

    return (
      <div className="employees">
        {isAdmin(myCompanyProfile) && !auth.emailVerified && (
          <NotActivatedWarning type={"employee"} />
        )}
        <ScrollableTabView
          onChangeTab={({ name }) => {
            // if on the advisors tab set the role to advisor
            if (name === I18n.t("employees.advisors")) {
              this.setState({ role: UserRole.Advisor });
              return;
            }

            if (this.state.role !== "") {
              this.setState({ role: "" });
            }
          }}
          renderTabBar={() => (
            <TabBarSearchSort
              showSort={false}
              searchComponent={
                <SearchInput
                  onChange={text => {
                    actions.filters(company.key, {
                      userSearch: text,
                    });
                  }}
                  search={search}
                />
              }
              onCloseSearch={() => {
                actions.filters(company.key, {
                  userSearch: "",
                });
              }}
              sortComponent={
                <div
                  onClick={() => this.setState({ page: 2 })}
                  style={{
                    backgroundColor: "yellow",
                  }}>
                  <Icon name={"ios-trash-outline"} key={"ios-trash-outline"} />
                </div>
              }
              beforeTabBarComponent={
                <EmployeesViewSwitch key="employees-view-switch" />
              }
              afterTabBarComponent={
                showNew
                  ? (
                    <CreateNewButton onClick={() => {
                      this.onClick();
                    }}
                    />
                  )
                  : null
              }
            />
          )}>
          <FlatList
            className="employees-list"
            tabLabel={I18n.t("employees.plural")}
            ListEmptyComponent={
              <NoResults
                text={
                  filter === "deactivated"
                    ? I18n.t("employees.noDeactivatedUsers")
                    : I18n.t("noSearchResults")
                }
              />
            }
            data={employees}
            renderItem={({ item }) => (
              <Employee
                user={item}
                company={company}
                actions={actions}
                myCompanyProfile={myCompanyProfile}
                selected={selectedEmployee === item.key}
              />
            )}
          />

          <FlatList
            labelKey={"advisors"}
            className="employees-list"
            tabLabel={`${I18n.t("employees.advisors")}`}
            ListEmptyComponent={
              <NoResults
                text={
                  filter === "deactivated"
                    ? I18n.t("employees.noDeactivatedUsers")
                    : I18n.t("employees.noAdvisors")
                }
              />
            }
            data={advisors}
            renderItem={({ item }) => (
              <Employee
                user={item}
                company={company}
                actions={actions}
                myCompanyProfile={myCompanyProfile}
                selected={selectedEmployee === item.key}
              />
            )}
          />

          <FlatList
            className="employees-list"
            tabLabel={`${I18n.t("employees.deactivated")}`}
            ListEmptyComponent={
              <NoResults text={I18n.t("employees.noDeactivatedUsers")} />
            }
            data={deactivated}
            renderItem={({ item }) => (
              <Employee
                user={item}
                company={company}
                actions={actions}
                myCompanyProfile={myCompanyProfile}
                selected={selectedEmployee === item.key}
              />
            )}
          />
        </ScrollableTabView>

        {this.state.showCreateEmployeeDialog
          ? (
            <GlobalUserSearch
              show={this.state.showCreateEmployeeDialog}
              onClose={() => this.setState({ showCreateEmployeeDialog: false })}
              groupType="employee"
            />
          )
          : null}

        <AlertDialog
          show={this.state.alertDialog.isVisible}
          onClose={this.closeAlert.bind(this)}
          title={this.state.alertDialog.title}
          children={this.state.alertDialog.message}
          key="alert"
        />
      </div>
    );
  }
}
